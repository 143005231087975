import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Board } from "../components/board.component";
import { BoardContainer } from "../components/board-container.component";
import { useTilesPositionStatus } from "../hooks/tile-position-status.hook";
import {
  SET_BOARD_DIMENSIONS,
  SET_IMAGE_DIMENSION,
  SET_IMAGE_URL,
  UPDATE_GAME_SETTINGS,
  UPDATE_GAME_STATUS
} from "../store/constants";


export const TimeLimitScreen = () => {
  const areTilesAligned = useTilesPositionStatus();
  const dispatch = useDispatch();
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [imageWidth, setImageWidth] = useState(400);
  const [imageHeight, setImageHeight] = useState(400);
  const queryParameters = new URLSearchParams(window.location.search)
  const audio = queryParameters.get("audio");
  const vibration = queryParameters.get("vibration");
  const board = queryParameters.get("board");
  const isPortrait = queryParameters.get("portrait");
  let image = queryParameters.get("image");
  image =image.replaceAll(" ","%20");
  image = image.replaceAll("(","%28");
  image = image.replaceAll(")","%29");
  const payload = {
    rows: board,
    columns: board
  };
  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };
  useEffect(() => {
      if (areTilesAligned || !isGameStarted) {
        dispatch({ type: UPDATE_GAME_STATUS, payload: false });
      } else {
        dispatch({ type: SET_IMAGE_DIMENSION, payload:{width:imageWidth,height:imageHeight,isPortrait:isPortrait==='true'} });
        dispatch({ type: SET_IMAGE_URL, payload:image });
        dispatch({ type: SET_BOARD_DIMENSIONS, payload });
        dispatch({ type: UPDATE_GAME_STATUS, payload: true });
        dispatch({ type: UPDATE_GAME_SETTINGS, payload: {audio:audio,vibration:vibration} });
      }

  }, [ areTilesAligned, dispatch, isGameStarted]);

  const startGame = () => {
    setIsGameStarted(true);
  };

  useEffect(()=>{
    setTimeout(()=>{
      startGame();
    },100)
  })

  useEffect(()=>{
    console.log("Tiles Aligned",areTilesAligned+" "+isGameStarted)
    if(areTilesAligned && isGameStarted){
      window.history.replaceState(null, "Success", "/completed")
    }
  },[areTilesAligned])

  useEffect(()=>{
    console.log("image loading");
    getMeta(image, (err, img) => {
      console.log(img.naturalWidth,img.naturalHeight)
      setImageWidth(img.naturalWidth);
      setImageHeight(img.naturalHeight);
    });
  },[image])

  return (
    <div>
      <BoardContainer>
        {/*<h1 style={{ color: "white" }}>Time Limit: {formatTime(timeLeft)}</h1>*/}
        <Board />
        <br />
      </BoardContainer>
    </div>
  );
};
