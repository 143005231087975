import React, { useMemo, useEffect } from "react";
import { v4 } from "uuid";
import { Tile } from "./tile.component";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { SWAP_TILES } from "../store/constants";

const StyledBoard = styled.div`
  background-color: white;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.4);
  position: relative;
  width: ${({ width,isPortrait }) => isPortrait ? 400:width}px;
  height: ${({ height }) => height}px;
  zoom:  ${({ zoom }) => zoom};
`;

const useSwapDispatcher = () => {
  const selectedTiles = useSelector(({ game }) => game.tiles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTiles.length === 2) {
      dispatch({ type: SWAP_TILES });
    }
  }, [selectedTiles, dispatch]);
};

export const Board = () => {
  useSwapDispatcher();

  const [numOfRows, numOfColumns] = useSelector(({ board }) => [
    board.rows,
    board.columns
  ]);
  const imageUrl = useSelector(({ image }) => image.url);
  const [imageWidth, imageHeight,isPortrait] = useSelector(({ image }) => [
    image.width,
    image.height
  ]);

  const randomPositions = useMemo(() => {
    const alreadyAssignedPositions = {};
    const randomPositions = {};

    for (let j = 0; j < numOfRows; j++) {
      for (let i = 0; i < numOfColumns; i++) {
        let foundUniquePosition = false;

        while (!foundUniquePosition) {
          const randomX = Math.floor(Math.random() * numOfColumns);
          const randomY = Math.floor(Math.random() * numOfRows);

          if (!alreadyAssignedPositions[`${randomX}${randomY}`]) {
            randomPositions[`${i}${j}`] = [randomX, randomY];
            foundUniquePosition = true;
            alreadyAssignedPositions[`${randomX}${randomY}`] = true;
          }
        }
      }
    }
    return randomPositions;
  }, [numOfColumns, numOfRows]);

  const tiles = useMemo(() => {
    let tiles = [];

    for (let j = 0; j < numOfRows; j++) {
      const columns = [];

      for (let i = 0; i < numOfColumns; i++) {
        const [randomX, randomY] = randomPositions[`${i}${j}`];
        columns.push(
          <Tile
            key={v4()}
            imageUrl={imageUrl}
            x={i}
            y={j}
            randomX={randomX}
            randomY={randomY}
          />
        );
      }

      tiles.push(
        <div key={v4()} className="row">
          {columns}
        </div>
      );
    }

    return tiles;
  }, [imageUrl, numOfColumns, numOfRows, randomPositions]);

const zoom = () => {

  if(isPortrait){
    return 0.89
  }
    //For larger image (landscape images)
    if(window.innerWidth < imageWidth && window.innerWidth < 300){
      return 0.68;
    }if(imageWidth >= 400 && window.innerWidth < 400){
      return 0.89;
    }if (imageWidth >= 400 && window.innerWidth > 400 && window.innerWidth < 550){
      return 1
    }if (imageWidth >= 400 && window.innerWidth > 700 && window.innerWidth < 850){
      return 1.9
    }if (imageWidth >= 400 && window.innerWidth >= 850){
      return 2.5
    }

    //for small images (portrait images)
    if(window.innerWidth < imageWidth && window.innerWidth < 300){
      return 0.65;
    }if(imageWidth < 400 && window.innerWidth < 400 && window.innerWidth > 350){
      return 0.89;
    }if (imageWidth < 400 && window.innerWidth > 400 && window.innerWidth < 550){
      return 1.5
    }if (imageWidth < 400 && window.innerWidth > 700 && window.innerWidth < 850){
      return 2.5
    }if (imageWidth <  400 && window.innerWidth >= 850){
      return 3
    }
  };

  return (
    <StyledBoard width={imageWidth} height={imageHeight} isPortrait={isPortrait} zoom={zoom}>
      {tiles}
    </StyledBoard>
  );
};
